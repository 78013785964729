import React from 'react';
import classNames from 'classnames';
import Link from '../Link';
import Excellent from './svgs/trustpilot-excellent.svg';
import styles from './styles.module.scss';
interface TrustpilotMicrostarRatingProps {
  className?: string;
}
const TrustpilotMicrostarRating = ({
  className
}: TrustpilotMicrostarRatingProps) => {
  return <Link className={classNames(styles.link, className)} href="https://uk.trustpilot.com/review/www.accommodationforstudents.com" target="_blank" rel="noopener" data-sentry-element="Link" data-sentry-component="TrustpilotMicrostarRating" data-sentry-source-file="index.tsx">
      <Excellent className={styles.rating} data-sentry-element="Excellent" data-sentry-source-file="index.tsx" />
      <span className={styles.hiddenText}>
        Accommodation for Students is rated Excellent on Trustpilot
      </span>
    </Link>;
};
export default TrustpilotMicrostarRating;